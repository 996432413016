<template>
  <div class="finance_wrapper">
    <img src="@/assets/finance.jpg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .finance_wrapper{
        display: flex;
        justify-content: center;
        padding: 24px;
    }
</style>
